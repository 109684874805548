// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StartInventoryTransferDialog_warehouse-list__bD9vq {
  margin-top: 7px;
  border: 1px solid #a7a7a7;
  border-radius: 7px;
  padding: 14px;
  height: 300px;
  overflow-y: scroll;
}

.StartInventoryTransferDialog_warehouse-list__title__SlJ6c {
  font-size: 16px;
  font-weight: bold;
  margin: 0px;
}

.StartInventoryTransferDialog_warehouse-list__options__elf-t {
  margin-top: 7px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 4px;
}

.StartInventoryTransferDialog_warehouse-list__radiobox__Btdlp {
  margin-right: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/components/returns-queue/StartInventoryTransferDialog.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,aAAa;EACb,qCAAqC;EACrC,QAAQ;AACV;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".warehouse-list {\n  margin-top: 7px;\n  border: 1px solid #a7a7a7;\n  border-radius: 7px;\n  padding: 14px;\n  height: 300px;\n  overflow-y: scroll;\n}\n\n.warehouse-list__title {\n  font-size: 16px;\n  font-weight: bold;\n  margin: 0px;\n}\n\n.warehouse-list__options {\n  margin-top: 7px;\n  display: grid;\n  grid-template-columns: repeat(2, 1fr);\n  gap: 4px;\n}\n\n.warehouse-list__radiobox {\n  margin-right: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"warehouse-list": `StartInventoryTransferDialog_warehouse-list__bD9vq`,
	"warehouse-list__title": `StartInventoryTransferDialog_warehouse-list__title__SlJ6c`,
	"warehouse-list__options": `StartInventoryTransferDialog_warehouse-list__options__elf-t`,
	"warehouse-list__radiobox": `StartInventoryTransferDialog_warehouse-list__radiobox__Btdlp`
};
export default ___CSS_LOADER_EXPORT___;
